<template>
  <div>
    <div class="px-10 pt-10 grid place-items-center">
      <img :src="require('@/assets/images/logo.svg')" class="w-1/4 pt-8" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
